// export const API_BASE_URL = 'http://localhost:8000'
export const API_BASE_URL = 'https://api.cruizewatersport.ai'
// export const API_BASE_URL = 'http://3.141.192.236:8000'

export const MEDIA_URL = 'https://cruize-data-storage.s3.us-east-2.amazonaws.com'
export const GOOGLE_API_KEY = 'AIzaSyA7PtBJPMPZ_QEmieE0aQ11_eh8luE-Wyk'

export const ApiEndPoints = {
  AUTH: {
    me: `${API_BASE_URL}/api/v1/auth/dealer/profile`,
    updateProfile: `${API_BASE_URL}/api/v1/auth/dealer/update-profile`,
    login: `${API_BASE_URL}/api/v1/auth/dealer/login`,
    forgotPassword: `${API_BASE_URL}/api/v1/auth/dealer/forgot-password`,
    verifyOtp: `${API_BASE_URL}/api/v1/auth/dealer/otp-verification`,
    resendOtp: `${API_BASE_URL}/api/v1/auth/dealer/resend-otp`,
    resetPassword: `${API_BASE_URL}/api/v1/auth/dealer/reset-password`,
    changePassword: `${API_BASE_URL}/api/v1/auth/dealer/change-password`
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/auth/dealer/dashboard`
  },
  PROPERTY: {
    list: id => `${API_BASE_URL}/api/v1/admin/property/getAllOwnerProperty/${id}`,
    propertybyId: id => `${API_BASE_URL}/api/v1/admin/property/getProperty/${id}`,
    upsertPricing: id => `${API_BASE_URL}/api/v1/admin/property/updatePropertyPrice/${id}`
  },
  PROPERTY_SERVICE: {
    allocate: id => `${API_BASE_URL}/api/v1/auth/property-service/${id}`,
    allocatedServices: id => `${API_BASE_URL}/api/v1/auth/property-service/${id}`
  },
  PROPERTY_LOCATION: {
    upsertLocation: id => `${API_BASE_URL}/api/v1/auth/location/${id}`,
    getLocation: id => `${API_BASE_URL}/api/v1/auth/location/${id}`
  },
  SERVICE: {
    list: `${API_BASE_URL}/api/v1/admin/service`,
    upsertCharges: serviceId => `${API_BASE_URL}/api/v1/admin/service/updateCharges/${serviceId}`,
    getCharges: (businessOwnerId, serviceId) => `${API_BASE_URL}/api/v1/admin/service/${businessOwnerId}/${serviceId}`
  },
  SUB_SERVICE: {
    list: id => `${API_BASE_URL}/api/v1/auth/sub-service/user/${id}`,
    create: id => `${API_BASE_URL}/api/v1/auth/sub-service/${id}`,
    update: id => `${API_BASE_URL}/api/v1/auth/sub-service/${id}`
  },
  AVAILABILITY: {
    upsert: id => `${API_BASE_URL}/api/v1/auth/${id}`,
    get: id => `${API_BASE_URL}/api/v1/auth/${id}`
  },
  NOTIFICATION: {
    get: id => `${API_BASE_URL}/api/v1/admin/property/getBusinessOwnerNotifications/${id}`
  }
}
