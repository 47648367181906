import { Box, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Logo from '../assets/images/BoatBg.png'
import { useSettings } from 'src/@core/hooks/useSettings'

const LeftWrapper = styled(Box)(({ theme }) => ({
  height: '50vh',
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    height: '100%'
  }
}))

const LeftContainer = styled(Box)(({ theme }) => ({
  // borderRadius: '24px',
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  background: '#8C77B8',
  backgroundImage: `url(${Logo})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}))

const LoginIllustrationWrapper = styled(Box)(({ theme }) => ({
  marginTop: '125px',
  padding: '25px',
  maxWidth: '400px',
  alignItems: 'center'
}))

const RightWrapper = styled(Box)(({ theme }) => ({
  // margin: 'auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: 550
  }
}))

const AuthLayout = ({ children }) => {
  const theme = useTheme()
  // const { settings } = useSettings()
  // const { skin } = settings

  return (
    <>
      <Grid container className='content-right'>
        <Grid item xs={12} md={6}>
          <LeftWrapper>
            <LeftContainer>
              <LoginIllustrationWrapper>{/* <Logo width={'100%'} /> */}</LoginIllustrationWrapper>
            </LeftContainer>
          </LeftWrapper>
        </Grid>

        <Grid item xs={12} md={6} display='flex' justifyContent={'center'} alignItems={'center'}>
          <RightWrapper>{children}</RightWrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default AuthLayout
