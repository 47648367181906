import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import PublicLayoutAppBar from './components/publickLayoutAppbar'

const PublicLayoutWrapper = styled(Box)(({ theme }) => ({}))

const PublicLayout = props => {
  const { children } = props
  return (
    <>
      <PublicLayoutWrapper>
        <PublicLayoutAppBar />
        <Box
          sx={{
            overflowX: 'hidden',
            position: 'relative',
            backgroundColor: '#ECF3F3',
            minHeight: theme => `calc(100vh - ${theme.spacing(theme.mixins.toolbar.minHeight / 4)})`
          }}
        >
          {children}
        </Box>
      </PublicLayoutWrapper>
    </>
  )
}
export default PublicLayout
