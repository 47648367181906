import { useEffect, useState, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Card,
  Drawer,
  Collapse,
  Divider,
  Badge
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import {
  Menu as MenuIcon,
  MoveToInbox as InboxIcon,
  Mail as MailIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ExpandLess,
  ExpandMore,
  Logout as LogoutIcon
} from '@mui/icons-material'

import CruzLogo from 'src/@core/components/icons/CruzLogo'
import NotificationIcon from 'src/@core/components/icons/notificationIcon'
import Paaji from 'src/assets/images/paaji.svg'
import { useAuth } from 'src/hooks/useAuth'
import { ApiEndPoints, MEDIA_URL } from 'src/network/endpoints'
import { axiosInstance } from 'src/network/adapter'
import { toastError } from 'src/utils/utils'

// Styled components (moved to top for better organization)
const AppBarWrapper = styled(AppBar)(() => ({
  backgroundColor: '#FFFFFF',
  transition: 'background-color 0.3s ease-in-out'
}))

const UserAvatar = styled(Avatar)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: '38px',
  height: '38px'
}))

const NotificationAvatar = styled(Badge)(() => ({
  borderRadius: '200px',
  border: '1px solid #0D1E32',
  backgroundColor: 'transparent',
  padding: 12
}))

const ListTextTypo = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '16px',
    color: theme.palette.secondary.main,
    fontWeight: 600
  }
}))

const DrawerHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))

const PublicLayoutAppBar = () => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, logout } = useAuth()

  // State management
  const [scroll, setScroll] = useState(false)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [anchorElNotification, setAnchorElNotification] = useState(null)
  const [notification, setNotification] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)

  // Memoized handlers
  const handleDrawerToggle = useCallback(() => {
    setOpenDrawer(prev => !prev)
  }, [])

  const handleUserMenuToggle = useCallback(event => {
    setAnchorElUser(current => (current ? null : event.currentTarget))
  }, [])

  const handleNotificationToggle = useCallback(event => {
    setAnchorElNotification(current => (current ? null : event.currentTarget))
  }, [])

  const handleNavigate = useCallback(
    path => {
      navigate(path)
      setOpenDrawer(false)
    },
    [navigate]
  )

  const handleLogout = useCallback(() => {
    logout()
    setAnchorElUser(null)
  }, [logout])

  // Fetch notifications
  const fetchNotifications = useCallback(async () => {
    try {
      const response = await axiosInstance.get(ApiEndPoints.NOTIFICATION.get(user._id))
      setNotification(response.data.notifications || [])
    } catch (error) {
      toastError(error)
    }
  }, [user._id])

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  // Scroll handler
  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 0)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // Reusable components
  const MenuItemButton = ({ path, label }) => (
    <ListItemButton
      sx={{
        backgroundColor: location.pathname === path ? 'primary.main' : 'transparent',
        borderRadius: '500px',
        border: location.pathname === path ? '1px solid #0D1E32' : 'none',
        paddingBlock: '5px'
      }}
      onClick={() => handleNavigate(path)}
    >
      <ListTextTypo primary={label} />
    </ListItemButton>
  )

  const NotificationCard = ({ message, time }) => (
    <Card sx={{ py: 1 }}>
      <MenuItem disableRipple onClick={() => setAnchorElNotification(null)}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Avatar alt='User' src={Paaji} />
          <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'secondary.main',
                lineHeight: '20px',
                fontWeight: 600,
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              }}
            >
              {message}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '16px', color: '#7B7B7B' }}>
            {time}
          </Typography>
        </Box>
      </MenuItem>
    </Card>
  )

  return (
    <AppBarWrapper elevation={0} scroll={scroll} position='sticky' sx={{ top: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          marginInline: { xs: '0rem', sm: '1rem', md: '5rem' },
          minHeight: '90px'
        }}
      >
        {/* Main content */}
        <Box display='flex' flexGrow={1}>
          {/* Logo section */}
          <Box flexGrow={1} display='flex' alignItems='center'>
            <Link to='/'>
              <CruzLogo width='130' height='50' fill='#0D1E32' />
            </Link>
          </Box>

          {/* Desktop Navigation */}
          <Box display={{ xs: 'none', md: 'flex' }} alignItems='center' gap={2}>
            <List>
              <ListItem sx={{ gap: 2, justifyContent: 'space-between', paddingX: 0 }}>
                <MenuItemButton path='/' label='Home' />
                <MenuItemButton path='/property' label='My Property' />
                <MenuItemButton path='/staff' label='My Staff' />
                <MenuItemButton label='My Booking' />
              </ListItem>
            </List>

            {/* Notification Icon */}
            <IconButton onClick={handleNotificationToggle}>
              <NotificationAvatar>
                <NotificationIcon fill='#0D1E32' size='21' />
              </NotificationAvatar>
            </IconButton>

            {/* User Menu */}
            <Button
              sx={{
                bgcolor: '#C1D7D6',
                padding: '4px 10px',
                ':hover': { bgcolor: '#C1D7D6' }
              }}
              onClick={handleUserMenuToggle}
              startIcon={
                <UserAvatar src={user.image?.url ? `${MEDIA_URL}${user.image.url}` : '/path-to-default-avatar.png'} />
              }
              endIcon={<ArrowDropDownIcon sx={{ color: '#000' }} />}
            >
              <Typography sx={{ color: theme.palette.primary.contrastText }}>{user.businessUserName}</Typography>
            </Button>
          </Box>

          {/* Mobile Navigation */}
          <Box display={{ xs: 'flex', md: 'none' }} alignItems='center' gap={1}>
            <IconButton onClick={handleNotificationToggle}>
              <NotificationAvatar>
                <NotificationIcon fill='#0D1E32' size='21' />
              </NotificationAvatar>
            </IconButton>
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon sx={{ color: '#0D1E32' }} fontSize='large' />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>

      {/* Notification Menu */}
      <Menu
        anchorEl={anchorElNotification}
        open={Boolean(anchorElNotification)}
        onClose={() => setAnchorElNotification(null)}
        sx={{
          mt: 2,
          '& .MuiMenu-paper': { backgroundColor: '#FFFFFF', borderRadius: '10px' }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box display='flex' flexDirection='column' gap={2}>
          <MenuItem disableRipple>
            <Typography sx={{ fontSize: '18px', color: 'secondary.main', fontWeight: 700 }}>Notifications</Typography>
          </MenuItem>
          {notification.map((item, index) => (
            <NotificationCard key={index} message={item.message} time='2 min' />
          ))}
        </Box>
      </Menu>

      {/* User Menu */}
      <Menu
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
        sx={{
          mt: '47px',
          '& .MuiMenu-paper': {
            borderRadius: '12px',
            width: '150px',
            bgcolor: '#C1D7D6'
          }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem sx={{ color: '#0D1E32' }}>My Feedbacks</MenuItem>
        <MenuItem sx={{ color: '#0D1E32' }}>Career</MenuItem>
        <MenuItem sx={{ color: '#0D1E32' }} onClick={() => handleNavigate('/profile')}>
          Profile
        </MenuItem>
        <MenuItem sx={{ color: '#0D1E32' }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>

      {/* Mobile Drawer */}
      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 250,
            backgroundColor: '#ECF3F3'
          }
        }}
        variant='persistent'
        anchor='right'
        open={openDrawer}
      >
        <DrawerHeaderWrapper>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon sx={{ color: '#0D1E32' }} />
            ) : (
              <ChevronRightIcon sx={{ color: '#0D1E32' }} />
            )}
          </IconButton>
        </DrawerHeaderWrapper>

        <Divider />

        <List>
          <ListItemButton onClick={() => setOpenDropdown(!openDropdown)} sx={{ justifyContent: 'space-between' }}>
            <Box display='flex' alignItems='center'>
              <ListItemIcon>
                <UserAvatar
                  src={user.image?.url ? `${MEDIA_URL}${user.image.url}` : '/path-to-default-avatar.png'}
                  sx={{ width: 30, height: 30 }}
                />
              </ListItemIcon>
              <Typography variant='fm-p2' sx={{ color: '#0D1E32', ml: 2 }}>
                {user.businessUserName}
              </Typography>
            </Box>
            {openDropdown ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={openDropdown} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon sx={{ color: 'primary.contrastText' }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' primaryTypographyProps={{ sx: { color: 'primary.contrastText' } }} />
              </ListItemButton>
            </List>
          </Collapse>
        </List>

        <Divider />

        <List>
          {[
            { path: '/', label: 'Home' },
            { path: '/property', label: 'My Property' },
            { path: '/staff', label: 'My Staff' },
            { path: '/booking', label: 'My Bookings' }
          ].map(item => (
            <ListItemButton
              key={item.path}
              onClick={() => handleNavigate(item.path)}
              sx={{
                backgroundColor: location.pathname === item.path ? 'primary.main' : 'transparent',
                borderRadius: '500px',
                border: location.pathname === item.path ? '1px solid #0D1E32' : 'none',
                my: 0.5
              }}
            >
              <ListTextTypo primary={item.label} />
            </ListItemButton>
          ))}
        </List>

        <Divider />

        <List>
          {[
            { path: '/contact', label: 'Contact Us', icon: <InboxIcon sx={{ color: '#0D1E32' }} /> },
            { path: '/faq', label: 'FAQ', icon: <MailIcon sx={{ color: '#0D1E32' }} /> }
          ].map(item => (
            <ListItemButton key={item.path} onClick={() => handleNavigate(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ sx: { color: '#0D1E32', fontWeight: 700 } }}
              />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </AppBarWrapper>
  )
}

export default PublicLayoutAppBar
