import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import UserLayout from './layouts/PublicLayout'

const LoginPage = React.lazy(() => import('./pages/login'))
const ForgotPassword = React.lazy(() => import('./pages/forgotPassword'))
const OtpVerification = React.lazy(() => import('./pages/otpVerification'))
const ResetPassword = React.lazy(() => import('./pages/resetPassword'))
const HomePage = React.lazy(() => import('./pages/home'))
const ProfilePage = React.lazy(() => import('./pages/profile'))
const PropertyPage = React.lazy(() => import('./pages/property'))
const PropertyDetailPage = React.lazy(() => import('./pages/propertyDetails'))
const StaffPage = React.lazy(() => import('./pages/staff'))
const StaffBookingPage = React.lazy(() => import('./pages/staffBookings'))
const BookingPage = React.lazy(() => import('./pages/bookings'))
const BookingDetailPage = React.lazy(() => import('./pages/booking-Details'))
const StaffDetailsPage = React.lazy(() => import('./pages/staffDetails'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPassword />}></Route>
                <Route path='/otp-verification' element={<OtpVerification />}></Route>
                <Route path='/reset-password' element={<ResetPassword />}></Route>
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <UserLayout>
                <Outlet />
              </UserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path='/' element={<HomePage />} />
              <Route path='/property' element={<PropertyPage />} />
              <Route path='/property-details/:id' element={<PropertyDetailPage />} />
              <Route path='/staff' element={<StaffPage />} />
              <Route path='/staff-bookings' element={<StaffBookingPage />} />
              <Route path='/staff-details/:id' element={<StaffDetailsPage />} />
              <Route path='/bookings' element={<BookingPage />} />
              <Route path='/bookings-details' element={<BookingDetailPage />} />
              <Route path='/profile' element={<ProfilePage />} />
            </Route>
          </Route>
          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />
        </Routes>
      </AclGuard>
    </Suspense>
  )
}

export default App
